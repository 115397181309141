import moment from "moment"
import ApprovePass from "@/component/approvePass/import_latest_version_module.js"
import consumer_apply_aduitOverproofOnBusiness
    from "@/lib/data-service/haolv-default/consumer_apply_aduitOverproofOnBusiness"
import {formatStartNodeShow, operablePermissions, getProcessInstanceInfo} from "@/lib/data-service/haolv-default/design";
import WorkflowProcessLineForArray from "@/component/workflow-process-line-for-array/index.vue";
import RollbackModal from "@/page/admin/office/my-apply/components/process/RollbackModal.vue";
import DeleteMultiModal from "@/page/admin/office/my-apply/components/process/DeleteMultiModal.vue";
import QueryMultiUserModal from "@/page/admin/office/my-apply/components/process/QueryMultiUserModal.vue";
import ResolveModal from "@/page/admin/office/my-apply/components/process/ResolveModal.vue";
import AgreeModal from "@/page/admin/office/my-apply/components/process/AgreeModal.vue";
import RevokeModal from "@/page/admin/office/my-apply/components/process/RevokeModal.vue";
import RefuseModal from "@/page/admin/office/my-apply/components/process/RefuseModal.vue";
import DelegateModal from "@/page/admin/office/my-apply/components/process/DelegateModal.vue";
import AssigneeModal from "@/page/admin/office/my-apply/components/process/AssigneeModal.vue";
import AddMultiModal from "@/page/admin/office/my-apply/components/process/AddMultiModal.vue";   // 因公改版超标申请审批

export default {
    data() {
        return {
            params: {
                budgetAmount: '',
                taskId: '',
                status: 0,
                statusText: '',
                approvalReason: '',
                approvalData: [],// 审批进度
            },
            record: {},//最新一条审批记录

            opaList: [],

            modalConfig: {
                agreeVisible: false,
                commentVisible: false,
                delegateVisible: false,
                assigneeVisible: false,
                resolveVisible: false,
                refuseVisible: false,
                rollbackVisible: false,
                addMultiVisible: false,
                queryMultiUserVisible: false,
                revokeVisible: false,
                deleteMultiVisible: false
            },
            processInfo: {},
        }
    },
    components: {
        AddMultiModal,
        AssigneeModal,
        DelegateModal,
        RefuseModal,
        RevokeModal,
        AgreeModal,
        ResolveModal,
        QueryMultiUserModal,
        DeleteMultiModal,
        RollbackModal,
        WorkflowProcessLineForArray,
        ApprovePass
    },
    filters: {
        format_time(val) {
            return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : ""
        }
    },
    computed: {
        showMoreOpa() {
            let moreBtnList = [];
            let moreBtn = ['reject', 'frontJoin', 'backJoin', 'addAssignee', 'delAssignee'];
            this.opaList.forEach(value => {
                if (moreBtn.indexOf(value.key) > -1 && value.checked) {
                    moreBtnList.push(value);
                }
            })
            return moreBtnList;
        },
    },
    methods: {
        //初始化
        init(params) {
            this.params = {...this.params, ...params}
            console.log(this.params);

            this.processInfo = {
                processInstanceId: this.params.taskId,
                taskId: this.params.taskId,
                signFlag: null,
                currentUserInfo: {
                    id: this.$store.state.userInfo.id,
                },
            }

            formatStartNodeShow({
                processInstanceId: this.$route.query.applyNo || this.params.taskId,
                userId: this.$store.state.userInfo.id,
                deptId: this.$store.state.userInfo.deptId,
                budgetAmount: this.params.budgetAmount,
            }).then(res=> {
                let list = res.result;
                let form = {
                    line: list
                }
                this.$refs.workflowProcessLineForArray.init(form);
            });

            // 只有审批中才能调
            if (this.params.status === 1) {
            //if(parseInt(this.$route.query.approveStatus) === 1){
                operablePermissions({
                    processInstanceId: this.$route.query.applyNo,
                    userId: this.$store.state.userInfo.id,
                    taskId: this.params.taskId,
                }).then((result)=>{
                    this.opaList = result.result;
                })

                getProcessInstanceInfo(this.$route.query.applyNo, this.params.taskId).then(resultInfo=>{
                    const formData = { ...resultInfo.result.formData };
                    console.log('formData', formData);
                    this.processInfo.formData = formData;
                })
            }

        },
        // 审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效，5.发起人
        getRecordStatus(val) {
            if (val === 0) {
                return 'wait'
            } else if (val === 1) {
                return 'wait'
            } else if (val === 2) {
                return 'pass'
            } else if (val === 3 || val === 4) {
                return 'fail'
            } else if (val === 6) {
                return 'lose'
            } else {
                return ''
            }
        },
        //是否审批结束
        recordEnd(val) {
            if (!Object.keys(val).length) {
                return false
            }
            if (val.approveStatus === 2 || val.approveStatus === 3 || val.approveStatus === 4) {
                return true
            } else {
                return false
            }
        },
        // 审批通过
        applyPass() {
            this.$refs.approvePass.run(this.params.btnParams.approvalParams).then(() => {
                this.$router.push({name: "admin-my-apply-manage-have-approve"})
            })
        },
        // 审批驳回
        applyReject() {
            this.$prompt("拒绝理由：", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /^\S/,
                inputErrorMessage: "请输入拒绝理由",
            })
                .then((value) => {
                    let data = {
                        applyNo: this.params.btnParams.approvalParams.applyNo,
                        approveStatus: 3,   // 审核状态：2.通过，3.拒绝
                        rejectReason: value.value,
                    }
                    consumer_apply_aduitOverproofOnBusiness(data).then((res) => {
                        this.$message({type: "error", message: "审批驳回！"})
                        this.$router.push({name: "admin-my-apply-manage-have-approve"})
                    })
                })
        },

        handleCommand(val) {
            console.log(val);
            this.opaList.forEach(value => {
                if (value.key === val) {
                    this.newPass(value);
                }
            })
        },
        // 新审批
        newPass(val) {
            switch (val.key) {
                case 'pass':
                    // 通过
                    this.onAgree();
                    break
                case 'refuse':
                    // 拒绝
                    this.onRefuse();
                    break
                case 'reject':
                    // 驳回
                    this.onRollback();
                    break
                case 'frontJoin':
                    // 委派
                    this.onDelegateTask();
                    break
                case 'backJoin':
                    // 转办
                    this.onAssignee();
                    break
                case 'addAssignee':
                    // 加签
                    this.onAddMulti();
                    break
                case 'delAssignee':
                    // 减签
                    this.onDeleteMulti();
                    break
            }
        },
        onAgree() {
            this.modalConfig.agreeVisible = true;
        },
        onDelegateTask() {
            this.modalConfig.delegateVisible = true;
        },
        onResolveTask() {
            this.modalConfig.resolveVisible = true
        },
        onRefuse() {
            this.modalConfig.refuseVisible = true
        },
        onRevoke() {
            this.modalConfig.revokeVisible = true;
        },

        onAssignee() {
            this.modalConfig.assigneeVisible = true
        },
        onRollback() {
            this.modalConfig.rollbackVisible = true;
        },
        onAddMulti() {
            this.modalConfig.addMultiVisible = true;
        },
        onQueryMultiUsersInfo() {
            this.modalConfig.queryMultiUserVisible = true;
        },
        onDeleteMulti() {
            this.modalConfig.deleteMultiVisible = true;
        },
        _success() {
            this.back();
        },
        back() {
            this.$router.go(-1);
        }
    }
}
